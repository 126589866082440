import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { StaticImage } from "gatsby-plugin-image"

const Refuges = ({ data, location }) => {
  const intl = useIntl();
  const { title, shelters } = data.site.siteMetadata;

  return (
    <Layout location={location} siteTitle={title}>
      <Seo title={intl.formatMessage({id: "navlinks.shelters"})} lang={intl.locale} />
      <section>
        <div className="container-hero">
          <div className="text-hero">
            <h1 className="heading-hero">
              <FormattedMessage id="shelters.headingHero" />
            </h1>
            <h2 className="heading-hero">
              <FormattedMessage id="shelters.headingHero2" />
            </h2>
          </div>
          <StaticImage
            className="cover-slider"
            src="../images/rifugi.jpeg"
            alt=""
            placeholder="blurred"
          />
        </div>
      </section>
      <section className="shelters body">
        <div className="container">
          <div className="section-intro">
            <h3><FormattedMessage id="shelters.headingIntro" /></h3>
            <p><FormattedMessage id="shelters.paragraphIntro1" /></p>
            <p><FormattedMessage id="shelters.paragraphIntro2" /></p>
            <p><FormattedMessage id="shelters.paragraphIntro3" /></p>
          </div>
          <div className="collection-list-wrapper">
            <div role="list" className="collection-list">
              {shelters.filter(r => r.language === intl.locale)
                .map(s => s.social.map((links, i) => {
                    const getIndexFor = (i) => s.social.length - i;
                    return <div role="listitem" className="collection-item">
                      <div className="div-block shelter">
                        <div>
                          <div>
                            <div className="text-block-light"><FormattedMessage id={`shelters.location${getIndexFor(i)}`}/></div>
                            <h3><FormattedMessage id={`shelters.title${getIndexFor(i)}`}/></h3>
                            <div className="text-block"><FormattedMessage id={`shelters.text${getIndexFor(i)}`}/></div>
                          </div>
                        </div>
                        <div>
                        {links.map(l =><a className="link-inline" href={l.link} target="_blank" rel="noreferrer">
                          <FormattedMessage id={`shelters.${l.type}Link`}/>
                        </a>)}
                        </div>
                      </div>
                    </div>
                  })
                )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Refuges

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        shelters {
          language
          social {
            type
            link
          }
        }   
      }
    }

  }
`
